export const TYPE_TYPE_USER = 'type_user'
export const TYPE_ESPECE = 'espece'
export const TYPE_RACE = 'race'

export const TYPE_SMS_GROUPE = 'd_sms_group'
export const TYPE_SMS_UNIQUE = 'd_sms_unik'


export const LIST_FILTER = () => {
    return [
        {type: TYPE_TYPE_USER, libelle: 'Type de Compte'},
        {type: TYPE_ESPECE, libelle: 'Espèce'},
        {type: TYPE_RACE, libelle: 'Race'}
    ].sort((a,b) => a.libelle.localeCompare(b.libelle))
} 

export const LIST_TYPE_SMS = () => {
    return [
        {type: TYPE_SMS_GROUPE, libelle: 'SMS de Groupe'},
        {type: TYPE_SMS_UNIQUE, libelle: 'SMS à une personne'}
    ]
}
<template>
  <div class="row">
    <div class="col-12">
      <div class="text-center">
        <img
          :src="photoCreateur"
          v-if="hasPhotoCreateur"
        >
        <i
          class="las la-user la-3x"
          v-else
        />
        <h6>{{ nameCreateur }}</h6>
        <small>{{ titreCreateur }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        uid: {type: String, default: null},
        placement: {type: String, default: 'bottom'}
    },
    computed: {
        ...mapGetters({
            users: 'auth/users',
            typeComptes: 'auth/typeComptes'
        }),
        user(){
            if(this.uid !== null) {
                let u = this.users.find(item => item.uid === this.uid)
                if(u) return u
            }
            return null
        },
        hasPhotoCreateur(){
            if(this.user !== null) return this.user.photo !== null
            return false
        },
        photoCreateur(){
            return  (this.hasPhotoCreateur) ?  this.user.photo : null
        },
        nameCreateur(){
            return (this.user !== null)? this.user.fullname : ''
        },
        titreCreateur(){
            if(this.user !== null){
                let t = this.typeComptes.find(item => item.uid === this.user.typeCompte)
                if(t) return t.libelle
            }
            return '-'
        },
        is_asside(){
            return ['left', 'right'].includes(this.placement)
        }
    }

}
</script>

<style lang="scss" scoped>

</style>
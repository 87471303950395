<template>
  <div class="row group">
    <div class="col-12">
      <h5>{{ name }}</h5>
      <p>{{ description }}</p>
    </div>
    <div class="col-5 text-left">
      <div style="position: absolute; bottom: 5px;">
        {{ createAt |systemReadableTime }}
      </div>
    </div>
    <div class="col-7 text-right">
      <user-inline :uid="createdBy" />
    </div>
  </div>
</template>

<script>
import userInline from '../../admin/user/userInline.vue'
export default {
    components: {userInline},
    props: {
        groupe: {type: Object, default: null}
    },
    computed: {
        name(){
            return (this.groupe !== null)? this.groupe.name: '-'
        },
        description (){
            return (this.groupe !== null)? this.groupe.description: '-'
        },
        createAt(){
            return (this.groupe !== null) ?  this.groupe.createdAt: null
        },
        createdBy(){
            return this.groupe !== null ? this.groupe.createBy:null
        }
    }
}
</script>

<style lang="scss" scoped>
.group{
    padding: 10px;
    margin: 10px 20px 20px 10px;
    border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: rbga(#BB1B1B, 0.15);

}
</style>
<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row detail-group">
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <groupe-view :groupe="group" />
          </div>
        </div>
        <div class="row saved-filters">
          <div class="col-12">
            <h5>Paramètres enregistrés [{{ filtredSavedParams.length }}]</h5>
            <div class="filter-box">
              <save-param
                v-for="(sp, i) in filtredSavedParams"
                :key="i"
                :param="sp"
              />
            </div>
          </div>
        </div>
        <div class="row filter-header">
          <div class="col-8">
            <h5>Ajout Paramètre</h5>
          </div>
          <div class="col-4 text-right filter-actions">
            <a
              class="btn btn-sm btn-outline-primary"
              href="javascript:void(0)"
              @click.prevent="incrementFiltreIndex"
            ><i class="icofont icofont-plus" /></a>
            <a
              class="btn btn-sm btn-outline-danger"
              v-if="indexFiltre > 0"
              href="javascript:void(0)"
              @click.prevent="decrementFiltreIndex"
            ><i class="icofont icofont-close" /></a>
          </div>
        </div>
        <filtre-param
          v-for="i in indexFiltre"
          :key="i"
          :index="i"
          :group="group"
        />
      </div>
      <div class="col-6 list-users">
        <div class="row list-users-header">
          <div class="col-5">
            <h5>Membres [{{ nbMembers }}]</h5>
          </div>
          <div class="col-4" />
          <div class="col-3 text-right">
            <a
              href="javascript:void(0)"
              v-if="!selectAll"
              @click.prevent="setSelectAll(true)"
            >Select all</a>
            <a
              href="javascript:void(0)"
              v-else
              @click.prevent="setSelectAll(false)"
            >Deselect all</a>
          </div>
        </div>
          
        <div class="row">
          <div class="col-12 members-list">
            <user
              v-for="(u, i) in filtredUsers"
              :user="u"
              :key="i"
              :group="group"
            />
          </div>
          <div class="col-12 text-right">
            <a
              href="javascript:void(0)"
              class="btn btn-primary"
              @click.prevent="sendMembers"
            >Mettre à jour</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { mapGetters, mapMutations } from 'vuex'
  import navbar from '../../components/navbar.vue'
  import FiltreParam from '../../components/user/group/filtreParam.vue'
  import SaveParam from '../../components/user/group/saveParam.vue'
  import User from '../../components/user/group/user.vue'
  import GroupeView from '../../components/user/group/view.vue'
  
  import { TYPE_ESPECE, TYPE_RACE, TYPE_TYPE_USER } from '../../constants/group'
  import { Select } from '../../directive/select'
import { ADD_GROUP_MEMBERS } from '../../graphql/group'
  export default {
      directives: { Select },
      components: {navbar, FiltreParam, SaveParam,  User, GroupeView},
      data(){
          return {
            navbarItems: [
              {
                  libelle: 'Groupes d\'utilisateurs',
                  route: 'groups'
              },
              {
                libelle: 'Detail'
              }
            ],
            pageIcon: 'la-users',
            pageTitle: 'Détail',
            pageDescription: 'Détail du groupe d\'utilisateur',
            group: null,
            filtredUsers: []
          }
      },
      watch: {
        groups: {
          handler(){
             this.initGroup() 
          },
          deep: true
        },
        filtres: {
          handler(){
            this.filterUsersWithParams()
          },
          deep: true
        },
        params: {
          handler(){
            let params = [...this.params].filter(item => item.groupe === this.group.uid)
            console.log(params)
            params.forEach(item => {
              this.addFiltre(item)
            })
          },
          deep: true
        }
      },
      mounted(){
        this.initGroup()
        this.initMembers([])
        this.initFiltre()
        this.filtredUsers = []
        let params = [...this.params].filter(item => item.groupe === this.group.uid)
        console.log(params)
        params.forEach(item => {
          this.addFiltre(item)
        })
      },
      methods: {
        ...mapMutations({
          initFiltre: 'group/INIT_FILTRES',
          initMembers: 'group/SET_MEMBERS',
          addFiltre: 'group/ADD_FILTRE',
          incrementFiltreIndex: 'group/INCREMENT_NB_FILTRE',
          decrementFiltreIndex: 'group/DECREMENT_NB_FILTRE',
          deleteFiltre: 'group/REMOVE_FILTRE',
          setSelectAll: 'group/SET_SELECT_ALL'
        }),
        initGroup(){
          this.group = this.groups.find(item => item.uid === this.$route.params.uid)
        },
        filterUsersWithParams(){
           // type user
          let types = [...this.filtres].filter(item => item.type === TYPE_TYPE_USER).map(({value}) => value)
          let fespeces = [...this.filtres].filter(item => item.type === TYPE_ESPECE).map(({value}) => value)
          let fraces = [...this.filtres].filter(item => item.type === TYPE_RACE).map(({value}) => value)
          if(types.length > 0){
            this.filtredUsers = [...this.users].filter(item => types.includes(item.typeCompte))
            if(fespeces.length > 0){
              this.filtredUsers = this.filtredUsers.filter(item => [...this.animaux].filter(i => i.proprio === item.uid).map(({espece}) => espece).some(el => fespeces.includes(el)))
            }
          }else{
            if(fespeces.length > 0){
              this.filtredUsers = [...this.users].filter(item => [...this.animaux].filter(i => i.proprio === item.uid).map(({espece}) => espece).some(el => fespeces.includes(el)))
            }
            
          }
          if(fraces.length > 0){
              this.filtredUsers = this.filtredUsers.filter(item => [...this.animaux].filter(i => i.proprio === item.uid).map(({race}) => race).some(el => fraces.includes(el)))
          }
         
        },
        sendMembers(){
          let data = {
            groupe: this.group.uid,
            users: this.members.map(({user}) => user)
          }
          console.log(data)
          this.$apollo.mutate({
              mutation: ADD_GROUP_MEMBERS,
              variables: {
                "members": {
                  ...data
                }
              },
              update: (store, { data  }) => {
                  console.log(data)
              },
          }).then(({data}) => {
              console.log(data)
          }).catch((error) => {
              console.error(error)
          })
        }
      },
      computed: {
          ...mapGetters({
              me: 'auth/me',
              groups: 'group/groups',
              indexFiltre: 'group/nbFiltre',
              filtres: 'group/filtres',
              params: 'group/params',
              members: 'group/members',
              users: 'auth/users',
              animaux: 'identification/animaux',
              espece: 'identification/especes',
              races: 'identification/races',
              selectAll: 'group/selectAll'
          }),
          filtredSavedParams(){
            if(this.group !== null)
              return [...this.params].filter(item => item.groupe === this.group.uid)
            return []
          },
          nbMembers(){
            if(this.group !== null) return this.group.members.length
            return 0
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .detail-group{
    .list-users{
      border-left: 1px solid rgba(0,0,0,0.12);
      .list-users-header{
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(0,0,0,0.12)
      }
      .members-list{
        height: 500px;
        overflow-y: auto;
        scroll-behavior: smooth;
        margin-bottom: 30px;
      }
    }
    
    .saved-filters{
      margin-bottom: 20px;
      .filter-box{
        
        border-left: 1px solid rgba(0,0,0,0.12);
      }
    }
    .filter-header{
      .filter-actions{
        a{
          margin-left: 5px;
        }
       
      }
    }
    .list-users{

    }
  }
  </style>
  